import { EHeadingStyleVariant, Heading } from '@outdoorsyco/bonfire';
import cn from 'classnames';
import React from 'react';

import css from './SectionHeading.module.css';

interface ISectionHeadingProps {
  text: string;
  additionalClasses?: string;
}

export const SectionHeading = ({ text, additionalClasses = '' }: ISectionHeadingProps) => {
  return (
    <Heading variant={EHeadingStyleVariant.H2Alt} className={cn(css.title, additionalClasses)}>
      {text}
    </Heading>
  );
};
