import cn from 'classnames';
import React from 'react';

type TDesktopFilterContentProps = {
  isOpened: boolean;
  content: React.ReactNode;
  className?: string;
};

export const DesktopFilterContent = ({
  isOpened,
  content,
  className,
}: TDesktopFilterContentProps) => {
  if (!isOpened) return null;

  return (
    <div
      className={cn(
        'absolute left-0 right-0 z-50 p-8 pt-6 bg-white top-full -translate-y-1 rounded-b-lg shadow-500',
        className,
      )}>
      {content}
    </div>
  );
};
