import { stringify } from 'query-string';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { useRouter } from '@/hooks/useRouter';
import { getUserLocale } from '@/redux/selectors/auth/user';
import { getUserCurrency } from '@/redux/selectors/currency';
import { getUserFavorites } from '@/redux/selectors/favorites';
import { EListingSource } from '@/services/analytics/listings/types';
import apiRequest from '@/services/apiRequest';
import { IData as IRentalData, IMeta as IRentalMeta } from '@/services/types/search/rentals/id';
import { getSearchApi } from '@/utility/getCoreApi';
import { mapRentalsToTiles } from '@/utility/mapSearchResultToTile';

import { ETagSlug } from './Tags';

type THomePageRecommendationsResponse = {
  data: IRentalData[];
  meta?: IRentalMeta;
};

const EXPECTED_RENTALS = 12;

export const useRecommendations = (tag: ETagSlug, listingSource?: EListingSource) => {
  const router = useRouter();

  const userFavorites = useSelector(getUserFavorites);

  // For testing purposes allow to pass the address as a query param, and use
  // it as the default address instead of the user's location based on the IP.
  const address = router.query.address as string;
  const { data: locationRecommendations } = useHomeRecommendations(!router.isReady, tag, address);
  const { data: fallbackRecommendations } = useHomeRecommendations(
    // Skip the request if the location recommendations still loading, or we got less rentals than expected.
    !locationRecommendations?.data || locationRecommendations.data.length === EXPECTED_RENTALS,
    tag,
    'USA',
  );

  return useMemo(() => {
    // Return the loading tiles if the location recommendations are still loading, or we got
    // less rentals than expected and the fallback recommendations are still loading.
    if (
      !locationRecommendations?.data ||
      (locationRecommendations.data.length < EXPECTED_RENTALS && !fallbackRecommendations?.data)
    ) {
      return {
        loading: true,
        tiles: Array.from({ length: EXPECTED_RENTALS }, () => ({ loading: true })),
      };
    }

    return {
      loading: false,
      location:
        // Show the location if we got rentals only from the location recommendations.
        locationRecommendations.data.length >= EXPECTED_RENTALS &&
        locationRecommendations.meta?.city &&
        locationRecommendations.meta?.state
          ? `${locationRecommendations.meta.city}, ${locationRecommendations.meta.state}`
          : undefined,
      tiles: mapRentalsToTiles({
        rentals:
          // If we don't have enough rentals from the location recommendations, show the fallback recommendations.
          locationRecommendations.data.length < EXPECTED_RENTALS
            ? fallbackRecommendations?.data || []
            : locationRecommendations.data,
        favorites: userFavorites,
        queryParams: router.query,
        hasImageCarousel: true,
        listingSource,
      }),
    };
  }, [
    locationRecommendations?.data,
    locationRecommendations?.meta,
    fallbackRecommendations?.data,
    userFavorites,
    router.query,
    listingSource,
  ]);
};

const useHomeRecommendations = (skip: boolean, tag: ETagSlug, address = '') => {
  const currency = useSelector(getUserCurrency);
  const locale = useSelector(getUserLocale);

  return useSWR<THomePageRecommendationsResponse>(
    skip
      ? null
      : `${getSearchApi()}/rentals/homepage-recommendations?${stringify({
          raw_json: true,
          tag_slug: tag,
          address,
          currency,
          locale,
        })}`,
    (url: string) => apiRequest({ url }),
    {
      revalidateIfStale: false, // Avoid fetching from the stale cache
      revalidateOnMount: true, // Always fetch fresh data on mount
    },
  );
};
