import { ETextStyleVariant, Text } from '@outdoorsyco/bonfire';
import cn from 'classnames';
import React, { ButtonHTMLAttributes } from 'react';

type TFilterButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  label: string;
  value: string;
};

export const FilterButton = ({ label, value, className, ...props }: TFilterButtonProps) => {
  return (
    <button
      type="button"
      {...props}
      className={cn(
        'h-[100px] flex flex-col justify-center px-4 py-4 w-full text-left overflow-hidden',
        className,
      )}>
      <Text
        variant={ETextStyleVariant.LegalRegular}
        component="span"
        className="w-full text-gray-500 truncate">
        {label}
      </Text>

      <Text
        variant={ETextStyleVariant.LargeRegular}
        component="span"
        className="w-full text-gray-800 truncate">
        {value}
      </Text>
    </button>
  );
};
