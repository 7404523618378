import { EHeadingStyleVariant, Heading } from '@outdoorsyco/bonfire';
import cn from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';

import css from './Headline.module.css';

type THeadlineProps = {
  value: Array<string | string[]>;
};

export const Headline = ({ value }: THeadlineProps) => {
  return (
    <Heading
      component="div"
      variant={EHeadingStyleVariant.H1Alt}
      className={cn('flex flex-col mb-10 lg:mb-12', css.root)}>
      {value.map((v, index) => {
        if (typeof v === 'string' || v.length === 1) {
          return <span key={index}>{Array.isArray(v) ? v[0] : v}</span>;
        }

        return v.length > 1 ? <AnimatedHeadline key={index} value={v} /> : null;
      })}
    </Heading>
  );
};

const STAY_STILL_DURATION = 3000;
const ANIMATION_DURATION = 500;

type TAnimatedHeadlineProps = {
  value: string[];
};

const AnimatedHeadline = ({ value }: TAnimatedHeadlineProps) => {
  const map = useMemo(() => Object.assign({}, value), [value]);
  const [props, setProps] = useState({ index: 0, animated: false });

  useEffect(() => {
    if (props.animated) {
      const timeout = setTimeout(
        () => setProps(p => ({ index: (p.index + 1) % value.length, animated: false })),
        ANIMATION_DURATION,
      );

      return () => clearTimeout(timeout);
    }

    const timeout = setTimeout(
      () => setProps(p => ({ ...p, animated: true })),
      STAY_STILL_DURATION,
    );

    return () => clearTimeout(timeout);
  }, [props, value.length]);

  return (
    <span className="relative flex flex-col overflow-clip">
      <span className="w-0">&nbsp;</span>

      <span
        className={cn('absolute translate-y-0', {
          '!-translate-y-full duration-500 transition-transform': props.animated,
        })}>
        {map[props.index]}
      </span>

      <span
        className={cn('absolute translate-y-full', {
          '!translate-y-0 duration-500 transition-transform': props.animated,
        })}>
        {map[(props.index + 1) % value.length]}
      </span>
    </span>
  );
};
