import {
  Button,
  Card,
  EButtonColorVariant,
  ECardBorderRadiusSize,
  EHeadingStyleVariant,
  ETextStyleVariant,
  Heading,
  Text,
} from '@outdoorsyco/bonfire';
import Image from 'next/image';
import React, { ReactNode, useEffect, useRef, useState } from 'react';

import Anchor from '@/components/switchback/Anchor/Anchor';
import Link from '@/components/switchback/Link/Link';
import { trackRenterSearchedEvent } from '@/services/analytics/search';
import { ESearchFilterCategory } from '@/services/analytics/search/types';
import { ERentalType } from '@/services/analytics/types';
import { LocationGroupType } from '@/utility/surfacedLocation';

import css from './HeroCard.module.css';

export type THeroCardProps = {
  background: string;
  backgroundSizes?: string;
  title: ReactNode;
  description?: ReactNode;
  cta: string;
  url: string;
  searchFilterCategory: ESearchFilterCategory;
  myIPAddress?: string;
};

export const HeroCard = ({
  background,
  backgroundSizes,
  title,
  description,
  cta,
  url,
  searchFilterCategory,
  myIPAddress = '',
}: THeroCardProps) => {
  const descriptionRef = useRef<HTMLDivElement>(null);
  const [descriptionHeight, setDescriptionHeight] = useState(0);

  useEffect(() => {
    if (!descriptionRef.current) return;

    const observer = new ResizeObserver(([entry]) => {
      setDescriptionHeight(entry?.target.getBoundingClientRect().height || 0);
    });

    observer.observe(descriptionRef.current);

    return () => observer.disconnect();
  }, []);

  const handleClick = () => {
    trackRenterSearchedEvent({
      surfacedLocations: LocationGroupType.HOMEPAGE,
      totalAdults: null,
      totalChildren: null,
      totalInfants: null,
      locationQuery: null,
      source: 'home_search',
      isADAAccessible: false,
      isPetFriendly: false,
      flexibleDateRange: null,
      departureDate: null,
      returnDate: null,
      hasCategories: false,
      selectedCategory: null,
      towableYear: null,
      towableMake: null,
      towableModel: null,
      rentalType: ERentalType.RV,
      lastQuestionSeen: null,
      geoIp: myIPAddress,
      searchFilterCategory,
    });
  };

  return (
    <Link href={url} passHref legacy>
      <Anchor href={url} className="group" onClick={handleClick}>
        <Card borderRadiusSize={ECardBorderRadiusSize.Large} withBoxShadow={false}>
          <Card.Media className="rounded-2xl overflow-clip lg:aspect-[628/342] lg:!h-full !h-[342px]">
            <Image
              fill
              priority
              alt=""
              src={background}
              sizes={backgroundSizes}
              className="lg:group-hover:scale-110 transition-transform duration-700 ease-in-out"
            />

            <div className="absolute inset-0 opacity-50 bg-gradient-to-b from-transparent to-[rgba(0,0,0,0.4)] group-hover:opacity-100 transition-opacity duration-700 ease-in-out" />

            <div className="absolute bottom-0 left-0 right-0 flex flex-col px-8 py-6 text-white gap-6">
              <Heading component="div" variant={EHeadingStyleVariant.H4Alt} className={css.title}>
                {title}
              </Heading>

              {description && (
                <div
                  // For smoother transition, we need to use the exact height of the description container
                  // as max height on hover. Using a bigger or smaller value will make the transition less smooth.
                  style={{ ['--hero-card-desc-height' as string]: `${descriptionHeight}px` }}
                  className="hidden -mt-6 opacity-0 lg:block max-h-0 max-w-[375px] group-hover:max-h-[var(--hero-card-desc-height,100px)] group-hover:opacity-100 transition-all duration-700 ease-in-out">
                  <div ref={descriptionRef}>
                    <Text variant={ETextStyleVariant.SmallRegular} className="pt-6">
                      {description}
                    </Text>
                  </div>
                </div>
              )}

              <Button
                onDark
                label={cta}
                variant={EButtonColorVariant.Tertiary}
                className="self-start pointer-events-none lg:pointer-events-auto"
              />
            </div>
          </Card.Media>
        </Card>

        {description && (
          <Text
            variant={ETextStyleVariant.SmallRegular}
            className={`pt-6 lg:hidden ${css.description}`}>
            {description}
          </Text>
        )}
      </Anchor>
    </Link>
  );
};
