import cn from 'classnames';
import React, { forwardRef, HTMLAttributes, useEffect } from 'react';

import { useForwardRef } from '@/hooks/useForwardRef';

import css from './SectionWrapper.module.css';

export const SectionWrapper = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  function SectionWrapper({ children, className = '', ...props }, ref) {
    const rootRef = useForwardRef(ref);

    useEffect(() => {
      if (!rootRef.current) return;

      const observers: IntersectionObserver[] = [];

      rootRef.current.querySelectorAll('.section-animated-block').forEach(section => {
        // If section already visible on the screen, ignore the animation at all
        if (section.getBoundingClientRect().top < window.innerHeight) return;

        // Mark the section as it should be animated
        section.setAttribute('data-animation-triggered', 'false');
        section.setAttribute('data-to-be-animated', 'true');

        const observer = new IntersectionObserver(([entry]) => {
          if (!entry?.isIntersecting) return;

          // The animation is going to be triggered only once
          observer.disconnect();

          // Remove the observer from the list since already disconnected
          const observerIndex = observers.indexOf(observer);
          if (observerIndex !== -1) observers.splice(observerIndex, 1);

          // Set the data-animated attribute to true to trigger the animation
          entry.target.setAttribute('data-animation-triggered', 'true');
        });

        observer.observe(section);
        observers.push(observer);
      });

      return () => {
        observers.forEach(observer => {
          observer.disconnect();
        });
      };
    }, [rootRef]);

    return (
      <div ref={rootRef} {...props} className={cn(css.root, className)}>
        {children}
      </div>
    );
  },
);
