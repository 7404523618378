import { ETextStyleVariant, Icon, Link, Text } from '@outdoorsyco/bonfire';
import Image from 'next/image';
import React, { useState } from 'react';

import {
  EThumbnailType,
  TDestinationCardThumbnail,
} from '@/constants/home/signatureDestinationsAndParks';
import { useBreakpoint } from '@/hooks/useBreakpoint';

const ImageFallback: React.FC = () => (
  <div className="flex items-center justify-center w-full h-full">
    <Icon name="General.Camera.Off" className="text-2xl" />
  </div>
);

export const DestinationCardThumbnail = (props: TDestinationCardThumbnail & { sizes?: string }) => {
  const { isAboveDesktop } = useBreakpoint();
  const [isError, setIsError] = useState(false);

  const handleError = () => {
    setIsError(true);
  };

  if (props.type === EThumbnailType.ThumbnailImage) {
    if (props.url) {
      return (
        <div className="relative w-full h-full overflow-hidden bg-white rounded-lg bg-opacity-40 backdrop-blur-[10px]">
          <Link
            href={props.url}
            target={isAboveDesktop ? '_blank' : undefined}
            className="relative block h-full">
            {isError ? (
              <ImageFallback />
            ) : (
              <Image
                className="object-cover object-center rounded-lg"
                alt=""
                src={props.src as string}
                sizes={props.sizes}
                fill
                loading="lazy"
                onError={handleError}
              />
            )}
          </Link>
        </div>
      );
    }

    return (
      <div className="relative w-full h-full overflow-hidden bg-white rounded-lg bg-opacity-40 backdrop-blur-[10px]">
        {isError ? (
          <ImageFallback />
        ) : (
          <Image
            className="object-cover object-center rounded-lg"
            alt=""
            src={props.src}
            sizes={props.sizes}
            fill
            loading="lazy"
          />
        )}
      </div>
    );
  }

  if (props.type === EThumbnailType.ThumbnailText) {
    if (props.url) {
      return (
        <Link className="rounded-lg max-w-[71px] !no-underline" href={props.url}>
          <div className="text-center bg-white rounded-lg h-[62px] bg-opacity-40 backdrop-blur-[10px] text-primary-base">
            <Text
              className="relative top-1/2 -translate-y-1/2"
              variant={ETextStyleVariant.SmallRegular}>
              {props.textContent}
            </Text>
          </div>
        </Link>
      );
    }

    return (
      <div className="text-center bg-white rounded-lg h-[62px] max-w-[71px] bg-opacity-0 backdrop-blur-[10px] text-primary-base">
        <Text
          className="relative top-1/2 -translate-y-1/2"
          variant={ETextStyleVariant.SmallRegular}>
          {props.textContent}
        </Text>
      </div>
    );
  }

  return null;
};
