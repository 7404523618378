import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { getHeaderSearchDatesFilter } from '@/redux/selectors/globalHeader';
import { formatDateRange } from '@/utility/format-date';

import { FilterButton } from './FilterButton';

type TDateFilterButtonProps = {
  toggleFilter: () => void;
  className?: string;
};

export const DateFilterButton = ({ toggleFilter, className }: TDateFilterButtonProps) => {
  const intl = useIntl();

  const dates = useSelector(getHeaderSearchDatesFilter);

  return (
    <FilterButton
      aria-label={intl.formatMessage(
        {
          defaultMessage: 'Click to {hasValue, select, true {change the} other {add}} dates',
          id: 'BTFyHD',
          description: 'Home Search Widget > Dates Filter Aria Label',
        },
        {
          hasValue: !!dates?.from && !!dates?.to,
        },
      )}
      label={intl.formatMessage({
        defaultMessage: 'Depart / Return',
        id: '6/Wpos',
        description: 'Home Search Widget > Dates Filter Label',
      })}
      value={
        dates?.from && dates?.to
          ? `${formatDateRange(dates.from.toString(), dates.to.toString(), false)} ${
              dates?.flexible_days ? ` (±${dates?.flexible_days})` : ''
            }`
          : intl.formatMessage({
              defaultMessage: 'Any dates',
              id: 'SfiX5D',
              description: 'Home Search Widget > Dates Filter Placeholder',
            })
      }
      onClick={toggleFilter}
      className={className}
    />
  );
};
