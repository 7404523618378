import {
  Button,
  EButtonColorVariant,
  EHeadingStyleVariant,
  ETextStyleVariant,
  Heading,
  Text,
} from '@outdoorsyco/bonfire';
import { stringify } from 'query-string';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Link from '@/components/switchback/Link/Link';
import {
  ModularListingTile,
  ModularListingTileContent,
  ModularListingTileContentShort,
  ModularListingTileMedia,
} from '@/components/ui/ModularListingTile';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import useFavorites from '@/hooks/useFavorites';
import {
  trackListingImageViewedEvent,
  trackListingSelectedEvent,
} from '@/services/analytics/listings';
import { EListingSource } from '@/services/analytics/listings/types';
import {
  trackRecommendationTabSelected,
  trackViewAllRentalsClicked,
} from '@/services/analytics/search';
import { ESearchSource } from '@/services/analytics/search/types';
import { IRentalTile } from '@/utility/mapSearchResultToTile';

import css from './Recommendations.module.css';
import { ETagSlug, Tags } from './Tags';
import { useRecommendations } from './useRecommendations';

interface IRecommendationsProps {
  myIPAddress?: string;
}

export const Recommendations = ({ myIPAddress }: IRecommendationsProps) => {
  const { isAboveDesktop, isAboveTablet } = useBreakpoint();

  const [activeTag, setActiveTag] = useState(ETagSlug.GuestFavorite);
  const recommendations = useRecommendations(activeTag, EListingSource.HOMEPAGE_RECOMMENDATIONS);
  const recommendationsInitiallyTriggeredRef = useRef(false);

  const { addFavorite, removeFavorite } = useFavorites({
    eventSource: EListingSource.HOMEPAGE_RECOMMENDATIONS,
  });

  useEffect(() => {
    if (recommendations.loading) {
      return;
    }

    if (!recommendationsInitiallyTriggeredRef.current) {
      // avoid triggering the event when first loading the recommendations
      // with the default tag
      recommendationsInitiallyTriggeredRef.current = true;
      return;
    }

    // trigger the event after recommendations are loaded
    // to get the correct geoLocation
    trackRecommendationTabSelected({
      tag: activeTag,
      source: ESearchSource.HOMEPAGE_RECOMMENDATIONS,
      geoLocation: recommendations.location || 'USA',
      searcherGeoLocation: myIPAddress || '',
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recommendations.loading, activeTag]);

  const onClickRental = (rental: IRentalTile, index: number) => {
    trackListingSelectedEvent({
      rental,
      eventSource: EListingSource.HOMEPAGE_RECOMMENDATIONS,
      listingPlacement: index + 1,
      tab: activeTag,
    });
  };

  const onChangeImage = (rental: IRentalTile, index: number, nextIndex: number) => {
    trackListingImageViewedEvent(
      rental,
      EListingSource.HOMEPAGE_RECOMMENDATIONS,
      index + 1,
      nextIndex + 1,
    );
  };

  const viewAllHref = `/rv-search?${stringify({
    address: recommendations.location,
  })}`;

  const buildHeading2ndLine = () => {
    if (recommendations.location)
      return (
        <FormattedMessage
          defaultMessage="near {location}"
          values={{
            location: (
              <Link href={viewAllHref} legacy passHref>
                <a
                  href={viewAllHref}
                  className="underline hover:no-underline underline-offset-4 decoration-2">
                  {recommendations.location}
                </a>
              </Link>
            ),
          }}
          id="MtFB8P"
        />
      );

    return (
      (recommendations.loading && <>&nbsp;</>) || (
        <FormattedMessage defaultMessage="in the country" id="+Gb73f" />
      )
    );
  };

  // To avoid showing the fallback value for the second line of the heading every
  // single time we are loading next set of recommendations, we are going to use
  // a state to store the value of the second line value, and update it only after
  // the recommendations are loaded.
  const [heading2ndLine, setHeading2ndLine] = useState(() => buildHeading2ndLine());

  useEffect(() => {
    if (!recommendations.loading) setHeading2ndLine(buildHeading2ndLine());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recommendations]);

  const handleViewAllClick = () => {
    const eventData = {
      tab: activeTag,
      source: 'homepage-recommendations',
      geoLocation: recommendations.location || 'USA',
      searcherGeoLocation: myIPAddress || '',
    };

    trackViewAllRentalsClicked(eventData);
  };

  const handleTagChange = (tag: ETagSlug) => {
    setActiveTag(tag);
  };

  return (
    <div className="flex flex-col px-6 mx-auto max-w-xxxxl lg:px-20">
      <div className="flex flex-col gap-4 lg:gap-6 section-animated-block">
        <div>
          <Heading variant={EHeadingStyleVariant.H1Alt} className={css.heading}>
            <FormattedMessage defaultMessage="Find the best RV rentals" id="DaGido" />
          </Heading>

          <Heading component="div" variant={EHeadingStyleVariant.H1Alt} className={css.heading}>
            {heading2ndLine}
          </Heading>
        </div>

        <Text variant={ETextStyleVariant.LargeRegular} className={css.description}>
          <FormattedMessage
            defaultMessage="Explore the open road in the perfect RV rental. Or, get one delivered and set up for you."
            id="yq8PYC"
          />
        </Text>
      </div>

      <Tags active={activeTag} onChange={handleTagChange} />

      <div className="flex flex-col gap-4 lg:gap-16 section-animated-block">
        <div
          className={`grid gap-4 lg:gap-x-8 grid-cols-2 lg:grid-cols-3 xxxxl:grid-cols-4 ${css.grid}`}>
          {recommendations.tiles.map((rentalTile, index) => (
            <ModularListingTile
              key={`${activeTag}-${index}`}
              rentalTile={rentalTile}
              target={isAboveDesktop ? '_blank' : undefined}
              onClick={() => onClickRental(rentalTile, index)}>
              <ModularListingTileMedia
                autoRotateOnHover
                favoriteBlackFilled
                rentalTile={rentalTile}
                {...(recommendations.loading
                  ? {}
                  : {
                      largeSourceSize: false,
                      addFavorite: () => addFavorite(rentalTile, index, true),
                      removeFavorite: () => removeFavorite(rentalTile, index),
                      onSlideChange: nextIndex => onChangeImage(rentalTile, index, nextIndex),
                    })}
                className="aspect-square md:aspect-[407/236] !h-auto"
              />

              <div aria-hidden={!isAboveTablet} className="hidden md:block">
                <ModularListingTileContent rentalTile={rentalTile} />
              </div>

              <div aria-hidden={isAboveTablet} className="md:hidden">
                <ModularListingTileContentShort rentalTile={rentalTile} />
              </div>
            </ModularListingTile>
          ))}
        </div>

        <Link href={viewAllHref} legacy passHref>
          <Button
            variant={EButtonColorVariant.Tertiary}
            label={
              <FormattedMessage
                defaultMessage="View all<desktop> rentals</desktop>"
                values={{
                  desktop: value => (
                    <span aria-hidden className="hidden md:inline">
                      {value}
                    </span>
                  ),
                }}
                id="0EFHuC"
              />
            }
            href={viewAllHref}
            className="self-center"
            onClick={handleViewAllClick}
          />
        </Link>
      </div>
    </div>
  );
};
