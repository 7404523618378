import { Carousel } from '@outdoorsyco/bonfire';
import { memo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';

import {
  ModularListingTile,
  ModularListingTileContent,
  ModularListingTileMedia,
} from '@/components/ui/ModularListingTile';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import useFavorites from '@/hooks/useFavorites';
import { selectAuthenticated } from '@/redux/modules/auth/selectors';
import { getSavedRentals } from '@/redux/modules/savedRentals';
import { TRootState } from '@/redux/rootReducer';
import { getSavedRentalResults } from '@/redux/selectors/savedRentals';
import {
  trackListingImageViewedEvent,
  trackListingSelectedEvent,
} from '@/services/analytics/listings';
import { EListingSource } from '@/services/analytics/listings/types';
import { IRentalTile } from '@/utility/mapSearchResultToTile';

import { AnimateInsertion } from '../AnimateInsertion';
import { SectionHeading } from '../SectionHeading';
import { ViewMoreRentalsTile } from '../ViewMoreRentalsTile';
import css from './FavouriteListings.module.css';

export const FavouriteListings = memo(() => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectAuthenticated);
  const { data: savedRentals } = useSelector((state: TRootState) =>
    getSavedRentalResults(state, EListingSource.HOMEPAGE),
  );
  const { isAboveDesktop, isAboveTablet, isAboveXXXXL } = useBreakpoint();
  const { addFavorite, removeFavorite } = useFavorites({
    eventSource: EListingSource.HOMEPAGE,
  });

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getSavedRentals());
    }
  }, [dispatch, isAuthenticated]);

  const onClickRental = (rental: IRentalTile, index: number) => {
    trackListingSelectedEvent({
      rental,
      eventSource: EListingSource.HOMEPAGE,
      listingPlacement: index + 1,
    });
  };

  const onChangeImage = (rental: IRentalTile, index: number, nextIndex: number) => {
    trackListingImageViewedEvent(rental, EListingSource.HOMEPAGE, index + 1, nextIndex + 1);
  };

  const formatSavedRentals = (rentals: IRentalTile[]) => {
    if (!rentals.length) return [];

    return rentals.map((rental, idx) => (
      <ModularListingTile
        key={`saved-rental-${idx}`}
        rentalTile={rental}
        target={isAboveDesktop ? '_blank' : undefined}
        onClick={() => onClickRental(rental, idx)}>
        <ModularListingTileMedia
          rentalTile={rental}
          {...(rental.loading
            ? {}
            : {
                largeSourceSize: false,
                addFavorite: () => addFavorite(rental, idx),
                removeFavorite: () => removeFavorite(rental, idx),
                onSlideChange: nextIndex => onChangeImage(rental, idx, nextIndex),
              })}
          className="aspect-[407/236] !h-full [&>div]:!pb-0"
          showFirstImageOnly={!isAboveDesktop}
          favoriteBlackFilled
          autoRotateOnHover
        />

        <ModularListingTileContent rentalTile={rental} />
      </ModularListingTile>
    ));
  };

  const getDisplayRentals = () => {
    const formatted = formatSavedRentals(savedRentals);
    const maxFavourites = isAboveXXXXL ? 7 : 5;
    const hasMore = formatted.length >= 1;
    const display = hasMore
      ? [
          ...formatted.slice(0, maxFavourites),
          <ViewMoreRentalsTile
            key="show-all"
            href="/saved"
            text={intl.formatMessage({ defaultMessage: 'View wishlist', id: 'Q9GFsh' })}
          />,
        ]
      : formatted;

    return {
      formattedRentals: formatted,
      displayRentals: display,
    };
  };

  const { displayRentals } = getDisplayRentals();

  if (!isAuthenticated || !savedRentals?.length || !displayRentals.length) {
    return null;
  }

  return (
    <AnimateInsertion>
      <div className="px-6 mx-auto max-w-xxxxl lg:px-20">
        <div className="pb-6 md:pb-0 md:-mb-10">
          <SectionHeading
            text={intl.formatMessage({
              defaultMessage: 'Your favorites',
              id: '3kaFkO',
            })}
          />
        </div>

        {!isAboveTablet && (
          <div className="-mx-6">
            <Swiper
              className={`${css.favouriteListingsMobileCarousel}`}
              slidesPerView={1.2}
              centeredSlides={true}
              spaceBetween={16}
              effect="coverflow"
              coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: true,
              }}>
              {displayRentals.map((slide, index) => (
                <SwiperSlide key={index}>{slide}</SwiperSlide>
              ))}
            </Swiper>
          </div>
        )}

        {isAboveTablet && (
          <div className={`${css.favouriteListingsDesktopCarousel}`}>
            <Carousel
              key={displayRentals.length}
              content={displayRentals}
              freeMode
              slidesPerView={isAboveXXXXL ? 4 : 3}
              slidesPerGroup={isAboveXXXXL ? 4 : 3}
              spaceBetween={32}
            />
          </div>
        )}
      </div>
    </AnimateInsertion>
  );
});

FavouriteListings.displayName = 'FavouriteListings';
