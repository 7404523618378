import { ETextStyleVariant, Text } from '@outdoorsyco/bonfire';
import cn from 'classnames';
import React from 'react';
import { useIntl } from 'react-intl';

import { useLocationAutocompleteContext } from './LocationAutocompleteContext';

export const LocationFilterInput = () => {
  const intl = useIntl();

  const { isOpened, inputProps } = useLocationAutocompleteContext();

  return (
    <div className="relative flex flex-col justify-center w-full p-4 overflow-hidden text-left h-[100px]">
      <Text
        variant={ETextStyleVariant.LegalRegular}
        component="span"
        className="w-full text-gray-500 truncate">
        {intl.formatMessage({
          defaultMessage: 'Location',
          id: 'HJy2Bx',
          description: 'Home Search Widget > Location Filter Label',
        })}
      </Text>

      <Text
        variant={ETextStyleVariant.LargeRegular}
        component="span"
        className={cn('w-full text-gray-800 truncate', {
          invisible: isOpened || inputProps.value,
        })}>
        {intl.formatMessage({
          defaultMessage: 'Add location',
          id: 'kdWlt8',
          description: 'Home Search Widget > Location Filter Placeholder',
        })}
      </Text>

      <input
        {...inputProps}
        placeholder={intl.formatMessage({
          defaultMessage: 'Type to search',
          id: 'xWNpY0',
          description: 'Home Search Widget > Location Filter Input Placeholder',
        })}
        className={cn(
          'absolute inset-0 z-10 bg-transparent p-4 pt-[34px] text-ellipsis',
          'font-normal text-gray-800 tracking-normal text-lg',
          {
            'placeholder-transparent': !isOpened,
            'placeholder-gray-800': isOpened,
          },
        )}
      />

      {isOpened && <div className="absolute left-0 z-20 w-full bottom-1 h-0.5 bg-gray-50" />}
    </div>
  );
};
