import React, { memo } from 'react';

import { TSeoHomeSection } from '@/services/getHomePageData';

import { SeoLinksSection } from './SeoLinksSection';

interface ISEOLinksProps {
  seoData: TSeoHomeSection[];
}

export const SeoLinks = memo(({ seoData }: ISEOLinksProps) => {
  return (
    <div className="px-6 mx-auto max-w-xxxxl lg:px-20">
      {seoData.map(section => (
        <SeoLinksSection key={section.title || section.type} section={section} />
      ))}
    </div>
  );
});
SeoLinks.displayName = 'SeoLinks';
