import { ForwardedRef, useImperativeHandle, useRef } from 'react';

/**
 *
 * @description this is use in forwardedRef components that also needs
 * internal ref for some modification or event listener
 *
 * @example
 * const Component = forwardRef((props), ref) => {
 *  const internalRef = useForwardRef(ref)
 *
 *  return <div ref={internalRef} />
 * })
 *
 */
export const useForwardRef = <T>(forwardedRef: ForwardedRef<T>) => {
  const internalRef = useRef<T>(null);

  useImperativeHandle(forwardedRef, () => {
    if (!internalRef.current) {
      throw new Error('useForwardRef should only be used when ref is certainly defined');
    }

    return internalRef.current;
  });

  return internalRef;
};
