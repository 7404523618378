import useSWR from 'swr';

import apiRequest from '@/services/apiRequest';
import { getSearchApi } from '@/utility/getCoreApi';

interface INationalPark {
  name: string;
  location_name: string;
  slug: string;
  image_url: string;
  mobile_image_url: string;
  total_rentals: number;
  rentals: { primary_image_url: string; slug: string }[];
}

interface INationalParks {
  parks: INationalPark[];
}

export const useNationalParks = () => {
  return useSWR(`${getSearchApi()}/parks/homepage`, url =>
    apiRequest<INationalParks>({ url }, true),
  );
};
