import {
  Button,
  Card,
  EButtonColorVariant,
  ECardBorderRadiusSize,
  ECardMediaSize,
  ETextStyleVariant,
  Loader,
  Text,
} from '@outdoorsyco/bonfire';
import cn from 'classnames';
import Image, { StaticImageData } from 'next/image';
import Link from 'next/link';
import React from 'react';
import { useIntl } from 'react-intl';

import {
  EDestinationCardClassName,
  TDestinationCardThumbnail,
} from '@/constants/home/signatureDestinationsAndParks';

import css from './DestinationCard.module.css';
import { DestinationCardThumbnail } from './DestinationCardThumbnail';

interface IDestinationCard {
  imageSrc: string | StaticImageData;
  imageSrcMobile: string | StaticImageData;
  imageSizes?: string;
  name: string;
  locationName?: string;
  url?: string;
  thumbnails?: TDestinationCardThumbnail[];
  isMobile?: boolean;
  cardClassName?: EDestinationCardClassName | string;
  isLoading?: boolean;
  isSignatureDestination?: boolean;
}

export const DestinationCard = ({
  imageSrc,
  imageSrcMobile,
  imageSizes,
  name,
  locationName,
  url,
  thumbnails,
  isMobile = false,
  cardClassName = '',
  isLoading = false,
  isSignatureDestination = false,
}: IDestinationCard) => {
  const intl = useIntl();

  const cardContent = (
    <Card borderRadiusSize={ECardBorderRadiusSize.Large} withBoxShadow={false}>
      <Card.Media
        className={cn(
          'overflow-clip !h-[520px] rounded-2xl group',
          isLoading ? '' : css.cardImageContainer,
          css[cardClassName] || '',
        )}
        size={ECardMediaSize.Large}>
        {isLoading ? (
          <div className="h-full bg-gray-200">
            <Loader className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
          </div>
        ) : (
          <>
            <Image
              className="object-cover object-left rounded-2xl lg:group-hover:scale-110 transition-transform duration-700 ease-in-out"
              src={isMobile ? imageSrcMobile : imageSrc}
              sizes={imageSizes}
              alt=""
              fill
            />

            <div className="absolute inset-0 opacity-50 bg-gradient-to-b from-transparent to-[rgba(0,0,0,0.4)] group-hover:opacity-100 transition-opacity duration-700 ease-in-out" />

            <div className="absolute z-10 px-6 pb-8 text-white pointer-events-none bottom-16">
              <Text
                className={cn('font-serif font-medium text-[38px]', {
                  'pb-6': !locationName,
                  'pb-2': locationName,
                })}>
                {name}
              </Text>
              {locationName && (
                <Text className="pb-6 font-bold" variant={ETextStyleVariant.SmallBold}>
                  {locationName}
                </Text>
              )}

              <Button
                className="mb-6"
                label={intl.formatMessage({ defaultMessage: 'Book now', id: '1mLe+3' })}
                variant={EButtonColorVariant.Tertiary}
                onDark
              />
            </div>
          </>
        )}
      </Card.Media>
    </Card>
  );

  return (
    <div className="relative group">
      {isSignatureDestination ? (
        <a href={url || ''}>{cardContent}</a>
      ) : (
        <Link href={url || ''}>{cardContent}</Link>
      )}

      <div className="absolute z-20 mx-6 bottom-8">
        {!!thumbnails?.length && (
          <div
            className={cn(
              'grid grid-rows-[62px] p-[2px] bg-white rounded-lg gap-[2px] bg-opacity-50 backdrop-blur-[10px]',
              {
                'grid-cols-[repeat(4,minmax(0,71px))]': thumbnails.length === 4,
                'grid-cols-[repeat(3,minmax(0,71px))]': thumbnails.length === 3,
              },
            )}>
            {thumbnails.map((thumbnail, index) => (
              <DestinationCardThumbnail key={index} {...thumbnail} sizes="71px" />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
