import { createSelector } from 'reselect';

import { TRootState } from '@/redux/rootReducer';
import { getUserFavorites } from '@/redux/selectors/favorites';
import { EListingSource } from '@/services/analytics/listings/types';
import { IRentalTile, mapRentalsToTiles } from '@/utility/mapSearchResultToTile';

type TSavedRentalsData = TRootState['savedRentals'];

interface ISearchResult {
  data: IRentalTile[];
  isFetched: boolean;
  error: unknown;
}

export const getSavedRentalResults = createSelector<
  TRootState,
  EListingSource | undefined | null,
  ReturnType<typeof getUserFavorites>,
  TSavedRentalsData,
  TRootState['queryParams'],
  EListingSource | undefined | null,
  ISearchResult
>(
  getUserFavorites,
  state => state.savedRentals,
  state => state.queryParams,
  (_: TRootState, listingSource?: EListingSource | null) => listingSource,
  (favorites, savedRentals, queryParams, listingSource) => {
    const rentals = savedRentals.data?.filter(rental => !rental.unavailable) || [];

    return {
      ...savedRentals,
      data: mapRentalsToTiles({
        rentals,
        favorites,
        loading: false,
        hasImageCarousel: true,
        queryParams,
        ...(listingSource && { listingSource }),
      }),
    };
  },
);
