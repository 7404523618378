import querystring from 'query-string';
import { useSelector } from 'react-redux';

import { EHeaderTab } from '@/components/ui/SearchHeader/UniversalSearch/UniversalSearchContainer/menu';
import { ESearchFilters } from '@/constants/searchFilters';
import { getHeaderSearchVehicleTypeFilter } from '@/redux/selectors/globalHeader';
import { parseFlexibleDateRange } from '@/redux/selectors/queryParams';
import { trackRenterSearchedEvent } from '@/services/analytics/search';
import { ERentalType } from '@/services/analytics/types';
import { parseEventDate } from '@/services/analytics/utils';
import { getParamAsString } from '@/utility/queryParams';
import { LocationGroupType } from '@/utility/surfacedLocation';

export const useNavigateToRecentSearch = (onNavigate?: () => void) => {
  const vehicleType = useSelector(getHeaderSearchVehicleTypeFilter);

  return async (recentSearchURL: string) => {
    if (!recentSearchURL) return;

    onNavigate?.();

    const params = querystring.parse(recentSearchURL.split('?')[1] || '');

    const dateFrom = getParamAsString(params[ESearchFilters.DATE_FROM] || undefined);
    const dateTo = getParamAsString(params[ESearchFilters.DATE_TO] || undefined);
    const features = params[ESearchFilters.FILTER_FEATURE];

    await trackRenterSearchedEvent({
      surfacedLocations: LocationGroupType.RECENT_SEARCHES,
      totalAdults: Number(params[ESearchFilters.GUESTS_ADULTS]) || 0,
      totalChildren: Number(params[ESearchFilters.GUESTS_CHILDREN]) || 0,
      totalInfants: null,
      locationQuery: getParamAsString(params[ESearchFilters.ADDRESS] || undefined) || null,
      source: 'home_search',
      isADAAccessible: !!features?.includes('accessible'),
      isPetFriendly: !!features?.includes('pet_friendly'),
      flexibleDateRange: parseFlexibleDateRange(
        dateFrom,
        dateTo,
        params[ESearchFilters.FLEXIBLE_DAYS] || undefined,
      ),
      departureDate: (dateFrom && parseEventDate(dateFrom)) || null,
      returnDate: (dateTo && parseEventDate(dateTo)) || null,
      hasCategories: true,
      selectedCategory: (vehicleType as unknown as EHeaderTab) || EHeaderTab.ALL,
      towableYear: null,
      towableMake: null,
      towableModel: null,
      rentalType: ERentalType.RV,
      searchFilterCategory: null,
      lastQuestionSeen: null,
    });

    window.location.href = recentSearchURL;
  };
};
