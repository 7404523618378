import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { EVehicleType } from '@/redux/modules/globalHeader';
import { getHeaderSearchVehicleTypeFilter } from '@/redux/selectors/globalHeader';

import { FilterButton } from './FilterButton';

type TVehicleTypeFilterButtonProps = {
  toggleFilter: () => void;
  className?: string;
};

export const VehicleTypeFilterButton = ({
  toggleFilter,
  className,
}: TVehicleTypeFilterButtonProps) => {
  const intl = useIntl();

  const vehicleType = useSelector(getHeaderSearchVehicleTypeFilter);

  return (
    <FilterButton
      aria-label={intl.formatMessage({
        defaultMessage: 'Click to select the vehicle type',
        id: 'frUpX+',
        description: 'Home Search Widget > Vehicle Type Filter Aria Label',
      })}
      label={intl.formatMessage({
        defaultMessage: 'Vehicle type',
        id: 'o3q3OM',
        description: 'Home Search Widget > Vehicle Type Filter Label',
      })}
      value={(() => {
        if (vehicleType === EVehicleType.DRIVABLE) {
          return intl.formatMessage({
            defaultMessage: 'Drive',
            id: 'lvCPt0',
            description: 'Home Search Widget > Vehicle Type Filter Drive Selection',
          });
        }

        if (vehicleType === EVehicleType.TOWABLE) {
          return intl.formatMessage({
            defaultMessage: 'Tow',
            id: '7oFaAQ',
            description: 'Home Search Widget > Vehicle Type Filter Tow Selection',
          });
        }

        if (vehicleType === EVehicleType.DELIVERABLES) {
          return intl.formatMessage({
            defaultMessage: 'Set up',
            id: 'BRpyqP',
            description: 'Home Search Widget > Vehicle Type Filter Delivery Selection',
          });
        }

        return intl.formatMessage({
          defaultMessage: 'Drive, tow or set up',
          id: 'Cd/tyU',
          description: 'Home Search Widget > Vehicle Type Filter Placeholder',
        });
      })()}
      onClick={toggleFilter}
      className={className}
    />
  );
};
