import { Divider, EHeadingStyleVariant, Heading, Link, Text } from '@outdoorsyco/bonfire';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { TSeoHomeContentItem, TSeoHomeSection } from '@/services/getHomePageData';

const isNearbyContentType = (type: string) => type === 'nearby-content';

interface ISeoLinkProps {
  seoItem: TSeoHomeContentItem;
}

const SeoLink = memo(({ seoItem }: ISeoLinkProps) => {
  return (
    <Link
      className="inline-block text-gray-800 !no-underline leading-6 hover:text-gray-900 group"
      href={`${process.env.hostApp}/${seoItem.path}`}>
      <Text className="text-gray-800 text-300 group-hover:underline underline-offset-2">
        {seoItem.display}
      </Text>
      {!!seoItem.count && (
        <Text className="text-gray-900 opacity-50 text-300">
          <FormattedMessage
            defaultMessage={`{count, plural,one {# RV rental} other {# RV rentals}}`}
            id="03gRRd"
            values={{ count: seoItem.count }}
          />
        </Text>
      )}
    </Link>
  );
});
SeoLink.displayName = 'SeoLink';

interface ISectionProps {
  section: TSeoHomeSection;
}

export const SeoLinksSection = memo(({ section }: ISectionProps) => {
  const isNearbyContent = isNearbyContentType(section.type);

  return (
    <div className="pb-16 sm:pb-20 section-animated-block">
      {!isNearbyContent && <Divider className="mb-8" />}
      {section.title && (
        <Heading
          variant={EHeadingStyleVariant.H5}
          className="pb-8 text-left highlight autoType800 sm:pb-[26px]">
          {section.title}
        </Heading>
      )}
      <div
        className={isNearbyContent ? 'grid grid-cols-2 gap-7 md:grid-cols-4 lg:grid-cols-8' : ''}>
        {section.data.map(seoContent =>
          section.type === 'park-links' ? (
            <div key={seoContent.path} className="seo-link-wrapper">
              <SeoLink seoItem={seoContent} />
            </div>
          ) : (
            <SeoLink key={seoContent.path} seoItem={seoContent} />
          ),
        )}
      </div>
      {!isNearbyContent && <Divider className="mt-8" />}
    </div>
  );
});
SeoLinksSection.displayName = 'SeoLinksSection';
