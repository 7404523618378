import { stringify } from 'query-string';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { ESearchFilters } from '@/constants/searchFilters';
import { useRouter } from '@/hooks/useRouter';
import { getUserLocale } from '@/redux/selectors/auth/user';
import { getUserCurrency } from '@/redux/selectors/currency';
import { getUserFavorites } from '@/redux/selectors/favorites';
import { EListingSource } from '@/services/analytics/listings/types';
import apiRequest from '@/services/apiRequest';
import { IData as IRentalData } from '@/services/types/search/rentals/id';
import { getSearchApi } from '@/utility/getCoreApi';
import { getItemFromLocalStorage } from '@/utility/localstorage';
import { mapRentalsToTiles } from '@/utility/mapSearchResultToTile';

type TRecentlyViewedRentalsResponse = {
  data: IRentalData[];
};

export const useRecentlyViewedRentalTiles = (listingSource?: EListingSource) => {
  const router = useRouter();
  const currency = useSelector(getUserCurrency);
  const locale = useSelector(getUserLocale);
  const userFavorites = useSelector(getUserFavorites);
  const recentlyViewedRentals: number[] =
    JSON.parse(getItemFromLocalStorage('recentlyViewedRentals') || '[]') || [];

  // fetch data only when recentlyViewedRentals contains rental ids
  const { data: recentlyViewedRes } = useSWR<TRecentlyViewedRentalsResponse>(
    `${getSearchApi()}/rentals?${stringify({
      [ESearchFilters.IDS]: recentlyViewedRentals.join(','),
      [ESearchFilters.PAGE_LIMIT]: 7,
      [ESearchFilters.RAW_JSON]: true,
      currency,
      locale,
    })}`,
    (url: string) =>
      recentlyViewedRentals.length
        ? apiRequest<TRecentlyViewedRentalsResponse>({ url })
        : { data: [] },
  );

  return useMemo(
    () =>
      mapRentalsToTiles({
        rentals: recentlyViewedRes?.data || [],
        favorites: userFavorites,
        queryParams: router.query,
        hasImageCarousel: true,
        listingSource,
      }),
    [recentlyViewedRes?.data, userFavorites, router.query, listingSource],
  );
};
