import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

const ANIMATION_DURATION_MS = 700;

type TAnimateInsertionProps = {
  children: JSX.Element;
  delay?: number;
};

// When parts of a page is rendered after fetching data, it can be visually disruptive to see the content suddenly appear.
// This component helps smooth out the insertion of a section by animating it in after a short delay.
export const AnimateInsertion = ({ children, delay = 500 }: TAnimateInsertionProps) => {
  const [isAnimatingInsertion, setIsAnimatingInsertion] = useState(false);
  const [animateInsertionFinished, setAnimateInsertionFinished] = useState(false);

  useEffect(() => {
    let timeout = setTimeout(() => {
      setIsAnimatingInsertion(true);

      timeout = setTimeout(() => {
        setIsAnimatingInsertion(false);
        setAnimateInsertionFinished(true);
      }, ANIMATION_DURATION_MS);
    }, delay);

    return () => clearTimeout(timeout);
  }, [delay]);

  const contentRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    if (!contentRef.current || animateInsertionFinished) return;

    const observer = new ResizeObserver(([entry]) => {
      setContentHeight(entry?.target.getBoundingClientRect().height || 0);
    });

    observer.observe(contentRef.current);

    return () => observer.disconnect();
  }, [animateInsertionFinished]);

  return (
    <div
      style={
        !animateInsertionFinished
          ? { ['--animate-insertion-content-height' as string]: `${contentHeight}px` }
          : {}
      }
      className={cn({
        'transition-all duration-700 ease-in-out': !animateInsertionFinished,
        'max-h-0 opacity-0': !isAnimatingInsertion && !animateInsertionFinished,
        'max-h-[var(--animate-insertion-content-height)] opacity-100': isAnimatingInsertion,
      })}>
      <div ref={contentRef} className={cn({ 'overflow-hidden': !animateInsertionFinished })}>
        {children}
      </div>
    </div>
  );
};
