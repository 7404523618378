import { Card, ETextStyleVariant, SkeletonLine, Text } from '@outdoorsyco/bonfire';
import React from 'react';
import { useIntl } from 'react-intl';

import { formatCurrency } from '@/utility/currency';
import generateTestIdFrom from '@/utility/generateTestIdFrom';
import { IRentalTile } from '@/utility/mapSearchResultToTile';

import { RentalRating } from '../ListingTile/RentalRating';

type TListingTileContentProps = {
  rentalTile: IRentalTile;
};

export const ModularListingTileContentShort = ({ rentalTile }: TListingTileContentProps) => {
  const intl = useIntl();

  const infos = (
    rentalTile.isStay
      ? [rentalTile.details?.sleeps, rentalTile.details?.beds]
      : [rentalTile.details?.seats, rentalTile.details?.sleeps]
  ).filter(Boolean);

  return (
    <Card.Content className="flex flex-col w-full py-2">
      {(() => {
        if (rentalTile.loading) {
          return (
            <Text component="span" variant={ETextStyleVariant.SmallRegular}>
              <SkeletonLine width="80%" className="bg-gray-200" />
            </Text>
          );
        }

        return (
          !!infos.length && (
            <Text
              component="span"
              variant={ETextStyleVariant.SmallRegular}
              data-testid={generateTestIdFrom(rentalTile.title || '', 'listing-info')}>
              {infos.join(', ')}
            </Text>
          )
        );
      })()}

      {(() => {
        if (rentalTile.loading) {
          return (
            <Text component="span" variant={ETextStyleVariant.SmallRegular}>
              <SkeletonLine width="50%" className="bg-gray-200" />
            </Text>
          );
        }

        if (rentalTile.price) {
          const formattedCurrentPrice = formatCurrency({
            priceInCents: rentalTile.price.currentPrice,
            currency: rentalTile.currency,
            digits: 0,
            maxDigits: 0,
          });

          const fallbackPeriod = intl.formatMessage({ defaultMessage: 'night', id: 'R11T2e' });
          const period = rentalTile.price.period.toLowerCase();

          return (
            <Text
              component="span"
              variant={ETextStyleVariant.SmallRegular}
              aria-label={intl.formatMessage(
                {
                  description:
                    '[Price]: screen reader message for current price per period (day/night)',
                  defaultMessage: '{currentPrice} per {period}',
                  id: 'RgOVlk',
                },
                {
                  currentPrice: formattedCurrentPrice,
                  period: period || fallbackPeriod,
                },
              )}>
              {intl.formatMessage(
                {
                  description: '[Price]: current price formatting for a listing',
                  defaultMessage:
                    '<priceTemplate>{value}</priceTemplate><periodTemplate>/{period, select, day {day} night {night} other {}}</periodTemplate>',
                  id: 'eJ7KW9',
                },
                {
                  priceTemplate: (value: React.ReactNode) => <span aria-hidden>{value}</span>,
                  periodTemplate: (value: React.ReactNode) => <span aria-hidden>{value}</span>,
                  value: formattedCurrentPrice,
                  period: period || fallbackPeriod,
                },
              )}
            </Text>
          );
        }
      })()}

      {(() => {
        if (rentalTile.loading) {
          return (
            <Text component="span" variant={ETextStyleVariant.SmallRegular}>
              <SkeletonLine width="30%" className="bg-gray-200" />
            </Text>
          );
        }

        return (
          !!rentalTile.details?.ratingNumber && (
            <RentalRating
              rating={rentalTile.details.ratingNumber}
              quantity={rentalTile.details.ratingQtd}
              textVariant={ETextStyleVariant.SmallRegular}
            />
          )
        );
      })()}
    </Card.Content>
  );
};
