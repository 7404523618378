import {
  Banner,
  Button,
  EBannerBackgroundVariant,
  EButtonColorVariant,
  EHeadingStyleVariant,
  ETextStyleVariant,
  Heading,
  Text,
} from '@outdoorsyco/bonfire';
import Image from 'next/image';
import { memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import becomeAHostImage from '@/assets/home/become-a-host/become-a-host.jpg';
import { useBreakpoint } from '@/hooks/useBreakpoint';

// Move static messages outside component
const messages = {
  learnMore: { defaultMessage: 'Learn more', id: 'TdTXXf' },
  rentRig: { defaultMessage: 'Rent your rig. Earn big.', id: 'F1NBAf' },
  earnUp: { defaultMessage: 'Earn up to $50,000 a year.', id: 'tY0vQ0' },
};

export const BecomeAHost = memo(() => {
  const intl = useIntl();
  const { isAboveDesktop } = useBreakpoint();

  return (
    <div className="px-6 mx-auto max-w-xxxxl lg:px-20 section-animated-block">
      <Banner
        backgroundVariant={EBannerBackgroundVariant.White}
        className="flex items-center justify-center w-full !bg-[#D6D4CB] !border-none !rounded-2xl">
        {isAboveDesktop && (
          <Banner.Media className="flex-1 w-full h-full">
            <Image
              src={becomeAHostImage}
              alt="Become a host"
              width={616}
              height={349}
              className="object-cover lg:min-h-[370px] xl:min-h-[360px] xxl:min-h-full"
              priority
              loading="eager"
            />
          </Banner.Media>
        )}
        <Banner.Content className="flex flex-col items-start flex-1 py-12 pl-6 xl:py-0 lg:pl-12 gap-4 md:items-center lg:items-start">
          <div className="flex items-start">
            <Text className="mt-2 text-[50px]">$</Text>
            <Text className="text-gray-800 text-[95px] leading-[115%] tracking-[-4.742px]">50</Text>
            <Text className="pl-2 mt-5 text-gray-800 text-[60px]">k</Text>
          </div>

          <Heading variant={EHeadingStyleVariant.H5} className="hidden text-gray-800 lg:block">
            <FormattedMessage defaultMessage="Rent your rig. Earn big." id="F1NBAf" />
          </Heading>

          <div>
            <Heading variant={EHeadingStyleVariant.H4} className="block text-gray-800 lg:hidden">
              <FormattedMessage defaultMessage="Rent your rig." id="LyRKq/" />
            </Heading>
            <Heading variant={EHeadingStyleVariant.H4} className="block text-gray-800 lg:hidden">
              <FormattedMessage defaultMessage="Earn big." id="rTHPc1" />
            </Heading>
          </div>

          <Text variant={ETextStyleVariant.SmallRegular} className="text-gray-800">
            <FormattedMessage defaultMessage="Earn up to $50,000 a year." id="tY0vQ0" />
          </Text>

          <Button
            href="/become-a-host"
            label={intl.formatMessage(messages.learnMore)}
            variant={EButtonColorVariant.Primary}
            className="!bg-white !border-none !text-black w-fit"
          />
        </Banner.Content>
      </Banner>
    </div>
  );
});

BecomeAHost.displayName = 'BecomeAHost';
