import { RefObject, useEffect, useRef, useState } from 'react';

export const useSectionIsInView = (): [RefObject<HTMLDivElement>, boolean] => {
  const sectionRef = useRef<HTMLDivElement>(null);
  const [inView, setInView] = useState(false);

  useEffect(() => {
    if (!sectionRef.current) return;

    const observer = new IntersectionObserver(([entry]) => setInView(!!entry?.isIntersecting), {
      rootMargin: '-50% 0% -50% 0%',
      threshold: 0,
    });

    observer.observe(sectionRef.current);
    return () => observer.disconnect();
  }, []);

  return [sectionRef, inView];
};
