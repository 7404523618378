import { Text } from '@outdoorsyco/bonfire';
import React from 'react';

import css from './DestinationCard.module.css';

interface IDestinationCardDescription {
  destinationDescription: string;
  showDescriptionWhileInactive?: boolean;
}

export const DestinationCardDescription = ({
  destinationDescription,
  showDescriptionWhileInactive = false,
}: IDestinationCardDescription) => {
  return (
    <div
      className={`pt-10 ${css.destinationCardText} ${showDescriptionWhileInactive ? css.inactiveDescription : ''}`}>
      <Text>{destinationDescription}</Text>
    </div>
  );
};
