import React from 'react';

import { Headline } from './Headline';
import { THeroCardProps } from './HeroCard';
import { HeroCards } from './HeroCards';
import { StickySearchWidget } from './StickySearchWidget';
import { Trips } from './Trips';

type THeroProps = {
  headline: Array<string | string[]>;
  cards: THeroCardProps[];
  locale: string;
  myIPAddress?: string;
};

export const Hero = (props: THeroProps) => {
  return (
    <div className="px-6 mx-auto max-w-xxxxl lg:px-20">
      <Headline value={props.headline} />
      <StickySearchWidget />
      <Trips locale={props.locale} />
      <HeroCards cards={props.cards} myIPAddress={props.myIPAddress} />
    </div>
  );
};
