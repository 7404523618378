import {
  Button,
  Divider,
  EButtonColorVariant,
  EDividerVariant,
  ETextStyleVariant,
  Icon,
  Text,
} from '@outdoorsyco/bonfire';
import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { useBreakpoint } from '@/hooks/useBreakpoint';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { useRouter } from '@/hooks/useRouter';
import {
  getHeaderSearchAddressFilter,
  getHeaderSearchDatesFilter,
  getHeaderSearchGuestsFilter,
} from '@/redux/selectors/globalHeader';
import { formatDateRange } from '@/utility/format-date';
import { yieldToMain } from '@/utility/yieldToMain';

import { useApplySearch } from './applySearch';
import { DateFilterButton } from './DateFilterButton';
import { DateFilterContent } from './DateFilterContent';
import { GuestsFilterButton } from './GuestsFilterButton';
import { GuestsFilterContent } from './GuestsFilterContent';
import { LocationAutocompleteProvider } from './LocationAutocompleteContext';
import { LocationAutocompleteMenu } from './LocationAutocompleteMenu';
import { LocationFilterContent } from './LocationFilterContent';
import { LocationFilterProvider } from './LocationFilterContext';
import { LocationFilterInput } from './LocationFilterInput';
import { VehicleTypeFilterButton } from './VehicleTypeFilterButton';
import { VehicleTypeFilterContent } from './VehicleTypeFilterContent';

enum EHomeSearchWidgetFilter {
  Location = 'Location',
  Date = 'Date',
  Guests = 'Guests',
  VehicleType = 'Vehicle Type',
}

export const HomeSearchWidget = () => {
  const intl = useIntl();
  const router = useRouter();

  const { isAboveDesktop } = useBreakpoint();

  const addressSelection = useAddressSelection();
  const datesSelection = useDatesSelection();
  const guestsSelection = useGuestsSelection();

  const containerRef = useRef<HTMLDivElement>(null);

  const [openedFilter, setOpenedFilter] = useState<EHomeSearchWidgetFilter>();

  const userInteractedWithLocationRef = useRef(false);

  const setOpenedFilterYTM = (filter?: EHomeSearchWidgetFilter) => {
    yieldToMain(() => setOpenedFilter(filter));

    if (!userInteractedWithLocationRef.current && filter === EHomeSearchWidgetFilter.Location) {
      userInteractedWithLocationRef.current = true;
    }
  };

  const toggleTargetFilter = (targetFilter: EHomeSearchWidgetFilter) => {
    return () => setOpenedFilterYTM(openedFilter === targetFilter ? undefined : targetFilter);
  };

  const [, { update: updatePrevBaseUrl }] = useLocalStorage('prevBaseUrl');
  const applySearch = useApplySearch();

  const handleSearch = async () => {
    setOpenedFilter(undefined);
    updatePrevBaseUrl(router.route);
    await applySearch();
  };

  // Open the next filter in the sequence that is not filled
  const openNextFilter = () => {
    // Because we auto complete the location, it might get confusing for the
    // users who would like to update it (back button might not be that obvious).
    // Keep track of user interaction with the location, and always open the
    // location filter first if the user hasn't interacted with it yet.
    if (!addressSelection || !userInteractedWithLocationRef.current) {
      return setOpenedFilterYTM(EHomeSearchWidgetFilter.Location);
    }

    if (!datesSelection) return setOpenedFilterYTM(EHomeSearchWidgetFilter.Date);
    if (!guestsSelection) return setOpenedFilterYTM(EHomeSearchWidgetFilter.Guests);
    return setOpenedFilterYTM(EHomeSearchWidgetFilter.VehicleType);
  };

  useEffect(() => {
    // We are using dialogs for mobile, so we don't need to handle clicks outside the container
    if (!isAboveDesktop) return;

    const handlePressEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setOpenedFilterYTM(undefined);
      }
    };

    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setOpenedFilterYTM(undefined);
      }
    };

    document.addEventListener('keydown', handlePressEscape, true);
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('keydown', handlePressEscape, true);
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [isAboveDesktop]);

  return (
    <LocationFilterProvider onNavigateToRecentSearch={() => setOpenedFilter(undefined)}>
      <div ref={containerRef} className="relative w-full h-[48px] lg:h-[100px]">
        <button onClick={openNextFilter} className="w-full bg-white rounded-full lg:hidden">
          <div className="flex items-center px-4 py-1 gap-2 h-[48px]">
            <div className="min-w-[16px]">
              <Icon name="General.Search" width={16} height={16} />
            </div>

            <div className="flex flex-col w-full text-left">
              <Text variant={ETextStyleVariant.SmallBold} component="span" className="truncate">
                {addressSelection ||
                  intl.formatMessage({
                    defaultMessage: 'Where to?',
                    id: 'sMQIp3',
                    description: 'Home Search Widget > Mobile Location Filter Label',
                  })}
              </Text>

              <Text variant={ETextStyleVariant.LegalRegular} component="span" className="truncate">
                {[
                  datesSelection ||
                    intl.formatMessage({
                      defaultMessage: 'Dates',
                      id: 'rP8n0q',
                      description: 'Home Search Widget > Mobile Dates Filter Label',
                    }),
                  guestsSelection ||
                    intl.formatMessage({
                      defaultMessage: 'Guests',
                      id: 'qwUg3c',
                      description: 'Home Search Widget > Mobile Guests Filter Label',
                    }),
                ].join(' • ')}
              </Text>
            </div>
          </div>
        </button>

        <form
          onSubmit={async e => {
            e.preventDefault();
            await handleSearch();
          }}
          className={cn('hidden lg:flex items-center bg-white rounded-lg', {
            '!bg-gray-100 rounded-b-none': openedFilter,
          })}>
          <div
            className={cn('flex-grow pr-2 pl-4 rounded-l-[inherit]', {
              'bg-white z-60': openedFilter === EHomeSearchWidgetFilter.Location,
            })}>
            <LocationAutocompleteProvider
              isOpened={isAboveDesktop && openedFilter === EHomeSearchWidgetFilter.Location}
              onOpen={() => setOpenedFilterYTM(EHomeSearchWidgetFilter.Location)}
              onDataSelection={() => setOpenedFilterYTM(EHomeSearchWidgetFilter.Date)}>
              <LocationFilterInput />
              <LocationAutocompleteMenu />
            </LocationAutocompleteProvider>
          </div>

          <Divider height="100px" variant={EDividerVariant.Vertical} />

          <div
            className={cn('w-[165px] px-2', {
              'bg-white z-60': openedFilter === EHomeSearchWidgetFilter.Date,
            })}>
            <DateFilterButton toggleFilter={toggleTargetFilter(EHomeSearchWidgetFilter.Date)} />
          </div>

          <Divider height="100px" variant={EDividerVariant.Vertical} />

          <div
            className={cn('w-[165px] px-2', {
              'bg-white z-60': openedFilter === EHomeSearchWidgetFilter.Guests,
            })}>
            <GuestsFilterButton toggleFilter={toggleTargetFilter(EHomeSearchWidgetFilter.Guests)} />
          </div>

          <Divider height="100px" variant={EDividerVariant.Vertical} />

          <div
            className={cn('flex items-center w-[350px] gap-4 pl-2 pr-4 rounded-r-[inherit]', {
              'bg-white z-60': openedFilter === EHomeSearchWidgetFilter.VehicleType,
            })}>
            <VehicleTypeFilterButton
              toggleFilter={toggleTargetFilter(EHomeSearchWidgetFilter.VehicleType)}
            />

            <Button
              type="submit"
              label={intl.formatMessage({
                defaultMessage: 'Search',
                id: 'D5G/Wt',
                description: 'Home Search Widget > Search Button Label',
              })}
              variant={EButtonColorVariant.Tertiary}
            />
          </div>
        </form>

        <LocationFilterContent
          onNext={() => setOpenedFilterYTM(EHomeSearchWidgetFilter.Date)}
          nextButtonLabel={intl.formatMessage({
            defaultMessage: 'Next: enter dates',
            id: 'fUbsaE',
            description: 'Home Search Widget > Next to dates',
          })}
          isOpened={!isAboveDesktop && openedFilter === EHomeSearchWidgetFilter.Location}
          onClose={() => setOpenedFilterYTM(undefined)}
          onDataSelection={() => setOpenedFilterYTM(EHomeSearchWidgetFilter.Date)}
        />

        <DateFilterContent
          onDataSelection={() => {
            // Move automatically only for desktop view, mobile has its own controls.
            if (isAboveDesktop) setOpenedFilterYTM(EHomeSearchWidgetFilter.Guests);
          }}
          onBack={() => setOpenedFilterYTM(EHomeSearchWidgetFilter.Location)}
          onNext={() => setOpenedFilterYTM(EHomeSearchWidgetFilter.Guests)}
          nextButtonLabel={intl.formatMessage({
            defaultMessage: 'Next: guest details',
            id: 'bOq8a+',
            description: 'Home Search Widget > Next to guests',
          })}
          isOpened={openedFilter === EHomeSearchWidgetFilter.Date}
          onClose={() => setOpenedFilterYTM(undefined)}
        />

        <GuestsFilterContent
          onBack={() => setOpenedFilterYTM(EHomeSearchWidgetFilter.Date)}
          onNext={() => setOpenedFilterYTM(EHomeSearchWidgetFilter.VehicleType)}
          nextButtonLabel={intl.formatMessage({
            defaultMessage: 'Next: vehicle type',
            id: 'OJgPFt',
            description: 'Home Search Widget > Next to vehicle type',
          })}
          isOpened={openedFilter === EHomeSearchWidgetFilter.Guests}
          onClose={() => setOpenedFilterYTM(undefined)}
        />

        <VehicleTypeFilterContent
          onBack={() => setOpenedFilterYTM(EHomeSearchWidgetFilter.Guests)}
          onSearch={handleSearch}
          isOpened={openedFilter === EHomeSearchWidgetFilter.VehicleType}
          onClose={() => setOpenedFilterYTM(undefined)}
        />
      </div>
    </LocationFilterProvider>
  );
};

const useAddressSelection = () => {
  return useSelector(getHeaderSearchAddressFilter);
};

const useDatesSelection = () => {
  const dates = useSelector(getHeaderSearchDatesFilter);

  if (dates?.from && dates?.to) {
    return `${formatDateRange(dates.from.toString(), dates.to.toString(), false)} ${
      dates?.flexible_days ? ` (±${dates?.flexible_days})` : ''
    }`;
  }
};

const useGuestsSelection = () => {
  const intl = useIntl();

  const guests = useSelector(getHeaderSearchGuestsFilter);
  const totalGuests = (guests?.adults || 0) + (guests?.children || 0);

  if (totalGuests) {
    return intl.formatMessage(
      {
        defaultMessage: '{totalGuests, plural, one {# guest} other {# guests}}',
        id: '5Y37yc',
        description: 'Home Search Widget > Guests Filter Selected Value',
      },
      {
        totalGuests,
      },
    );
  }
};
