import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useHasBeenMounted } from '@/hooks/useHasBeenMounted';

import { HeroCard, THeroCardProps } from './HeroCard';
import css from './HeroCards.module.css';

type THeroCardsProps = {
  cards: THeroCardProps[];
  myIPAddress?: string;
};

export const HeroCards = ({ cards, myIPAddress }: THeroCardsProps) => {
  const hasBeenMounted = useHasBeenMounted();

  return (
    <>
      <div className="hidden gap-6 lg:grid grid-flow-col">
        {cards.map((card, index) => (
          <HeroCard key={index} {...card} backgroundSizes="50vw" myIPAddress={myIPAddress} />
        ))}
      </div>

      <div className="-mx-6 lg:hidden">
        <Swiper
          slidesPerView={1.2}
          centeredSlides={true}
          effect="coverflow"
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          className={css.swiper}>
          {cards.map((card, index) => (
            // Add transition effect only after the component has been mounted to avoid transition on initial render
            <SwiperSlide key={index} className={hasBeenMounted ? 'duration-[0.3s] ease-in' : ''}>
              <HeroCard key={index} {...card} backgroundSizes="85wv" myIPAddress={myIPAddress} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};
